<template>
  <div>tab 切换两个协议</div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
